import React from 'react';
import PropTypes from 'prop-types';

import './ReviewPhoto.scss';

const ReviewPhoto = ({ imageValue, isImageValid, dispatch, setActionType }) => (
  <div className='photo'>
    {imageValue && isImageValid && typeof window !== undefined ? (
      <img src={(window.URL || window.webkitURL).createObjectURL(imageValue)} />
    ) : (
      <p>No photo</p>
    )}
    {imageValue && (
      <div
        onClick={() => {
          dispatch({ type: setActionType, payload: '' });
          dispatch({ type: 'VALID_IMAGE' });
        }}
        className='photo-delete'
      >
        Delete
      </div>
    )}
  </div>
);

export default ReviewPhoto;

ReviewPhoto.propTypes = {
  imageValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isImageValid: PropTypes.bool,
  dispatch: PropTypes.func,
  setActionType: PropTypes.string
};
