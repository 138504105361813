export default {
  facebook: {
    link: data => data.link
  },
  wordpress: {
    link: data => data.link
  },
  foursquare: {
    link: data => data.link
  },
  youtube: {
    link: data => data.link
  },
  blogger: {
    link: data => data.link
  },
  google: {
    link: data => data.link
  },
  github: {
    link: data => data.profile_link
  },
  linkedin: {
    link: data => data.link
  },
  tripit: {
    link: data => data.link
  },
  tumblr: {
    link: data => data.link
  },
  gravatar: {
    link: data => data.link
  },
  twitter: {
    link: data => data.link
  },
  vimeo: {
    link: data => data.link
  }
};
