import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import usePreviosValue from '../../hooks/usePreviosValue';

const ReviewInput = ({
  value,
  text,
  type,
  name,
  dispatch,
  isValid,
  isExists,
  setActionType,
  validate,
  fetching,
  setValid,
  setExist
}) => {
  const previosValue = usePreviosValue(value);

  const inputHintEmailNotExist = isValid && !isExists && !fetching;
  const inputHintText = text && !fetching && isValid && isExists;

  const inputClasses = inputHintEmailNotExist
    ? `review-${name} review-${name}-not-exist`
    : `review-${name}`;

  const handeleBlur = useCallback(() => {
    if (name === 'E-mail') {
      if (value && previosValue !== value) {
        validate(name);
      }
    } else if (value) {
      validate(name);
    }
  }, [value, validate, name]);

  return (
    <div className='form-row'>
      <p>{name}</p>
      <input
        placeholder={name}
        className={inputClasses}
        type={type}
        value={value}
        onFocus={
          name === 'E-mail'
            ? () => {
                dispatch({ type: setValid });
                dispatch({ type: setExist });
              }
            : () => dispatch({ type: setValid })
        }
        onChange={e =>
          dispatch({
            type: setActionType,
            payload: e.target.value
          })
        }
        onBlur={handeleBlur}
        required
      />
      {fetching && (
        <p className='input-hint'>Please wait. We are checking your email</p>
      )}
      {!isValid && (
        <p className={`input-hint input-hint-${name}`}>{name} is not valid</p>
      )}
      {inputHintEmailNotExist && (
        <p className='input-hint'>This email is not exist</p>
      )}
      {inputHintText && <p className='input-hint'>{text}</p>}
    </div>
  );
};

export default ReviewInput;

ReviewInput.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  isExists: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  setActionType: PropTypes.string,
  validate: PropTypes.func,
  fetching: PropTypes.bool,
  setValid: PropTypes.string,
  setExist: PropTypes.string
};
