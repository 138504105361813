import React from 'react';
import PropTypes from 'prop-types';

import './CloseIcon.scss';

const CloseIcon = ({ openModal }) => (
  <div className='modal-close-icon' onClick={() => openModal(false)} />
);

export default CloseIcon;

CloseIcon.propTypes = {
  openModal: PropTypes.func
};
